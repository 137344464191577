import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout.js";
import PageSections from "../components/pageSections/index.js";
import SideBar from "../components/sideBar.js";
import { useState } from "react";

// markup
const AboutUsPage = ({ data, pageContext: { originalPath }, uri }) => {
  const [currentSection, setCurrentSection] = useState(0);

  const pageNode = data?.page?.nodes[0];
  if (!pageNode) {
    return null;
  }

  const sectionTitles = [];

  for (let i = 0; i < pageNode.sections.length; i++) {
    const section = pageNode.sections[i];
    if (section.title) {
      sectionTitles.push({ title: section.title });
    }
  }

  return (
    <Layout
      title={pageNode.title}
      description={pageNode.description}
      uri={uri}
      image={pageNode.image?.localFile.childImageSharp.original.src}
      imageAlt={pageNode.image?.description}
      mainNav={data.mainNav?.nodes[0]}
      globalNav={data.globalNav?.nodes[0]}
      footerNav={{
        primary: data.primaryFooterNav?.nodes[0],
        secondary: data.secondaryFooterNav?.nodes[0],
      }}
      originalPath={originalPath}
      headerTitle={pageNode.headerTitle}
      headerBackgroundImage={pageNode.headerBackgroundImage}
      settings={data.settings?.nodes[0]}
      hasDeco={false}
    >
      <SideBar sectionTitles={sectionTitles} currentSection={currentSection}>
        <PageSections
          sections={pageNode.sections}
          setCurrentSection={setCurrentSection}
        />
      </SideBar>
    </Layout>
  );
};

export default AboutUsPage;

export const data = graphql`
  query aboutUsPageQuery($hrefLang: String!) {
    globalNav: allContentfulNavigation(
      filter: { contentfulid: { eq: "global" }, node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...NavLinks
      }
    }
    mainNav: allContentfulNavigation(
      filter: { contentfulid: { eq: "main" }, node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...NavLinks
      }
    }
    primaryFooterNav: allContentfulNavigation(
      filter: {
        contentfulid: { eq: "footer-primary" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...NavLinks
      }
    }
    secondaryFooterNav: allContentfulNavigation(
      filter: {
        contentfulid: { eq: "footer-secondary" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...NavLinks
      }
    }
    settings: allContentfulSiteSettings(
      filter: { node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...SiteSettings
      }
    }
    page: allContentfulPage(
      filter: {
        contentfulid: { eq: "about-us" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...Page
      }
    }
  }
`;
